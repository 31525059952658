import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/AccountManagement.css';

const AccountDelete = ({ onCancel, onDelete, onError }) => {
    const { sessionToken, userId, logout } = useContext(AuthContext);
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [acknowledgments, setAcknowledgments] = useState({
        understand: false,
        permanent: false,
        noRecovery: false
    });

    const handleDelete = async () => {
      try {
        const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/delete`, {
          method: 'DELETE',
          credentials: 'include',
                headers: {
            'Content-Type': 'application/json',
            'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
            'Authorization': `Bearer ${sessionToken}`
          },
          body: JSON.stringify({ password })
        });

        if (!response.ok) throw new Error('Failed to delete account');

        setTimeout(() => {
          logout();
        }, 2000);
      } catch (err) {
        onError('Failed to delete account. Please check your password and try again.');
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (confirmText.toLowerCase() !== 'delete my account') {
        onError('Please type "delete my account" to confirm');
        return;
      }

      if (!Object.values(acknowledgments).every(Boolean)) {
        onError('Please acknowledge all warnings');
        return;
      }

      setIsSubmitting(true);
      try {
        await handleDelete();
        onDelete();
      } catch (error) {
        onError('Failed to delete account');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
        <div className="modal-overlay">
            <div className="account-modal delete">
                <div className="modal-header danger">
                    <div className="header-icon">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="danger-icon">
                            <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor"/>
                        </svg>
                    </div>
                    <h2>Delete Account</h2>
                    <p>This action cannot be undone</p>
                </div>

                <div className="modal-body">
                    <div className="danger-box">
                        <h3>Warning: Account Deletion is Permanent</h3>
                        <ul>
                            <li>All your data will be permanently deleted</li>
                            <li>You will lose access to all services and subscriptions</li>
                            <li>Your username will be released</li>
                            <li>This action cannot be reversed</li>
                        </ul>
                    </div>

                    <form onSubmit={handleSubmit} className="confirmation-form">
                        <div className="acknowledgments">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={acknowledgments.understand}
                                    onChange={(e) => setAcknowledgments({
                                        ...acknowledgments,
                                        understand: e.target.checked
                                    })}
                                />
                                <span>I understand that this will delete my entire account</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={acknowledgments.permanent}
                                    onChange={(e) => setAcknowledgments({
                                        ...acknowledgments,
                                        permanent: e.target.checked
                                    })}
                                />
                                <span>I understand this action is permanent</span>
                            </label>

                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={acknowledgments.noRecovery}
                                    onChange={(e) => setAcknowledgments({
                                        ...acknowledgments,
                                        noRecovery: e.target.checked
                                    })}
                                />
                                <span>I understand my data cannot be recovered</span>
                            </label>
                        </div>

                        <div className="form-group">
                            <label htmlFor="delete-password">Enter your password</label>
                            <input
                                type="password"
                                id="delete-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="form-input"
                                placeholder="Enter your current password"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirm-delete">
                                Type "delete my account" to confirm
                                <span className="confirmation-required">Required</span>
                            </label>
                            <input
                                type="text"
                                id="confirm-delete"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                                required
                                className="form-input"
                                placeholder="delete my account"
                            />
                        </div>

                        <div className="modal-actions">
                            <button
                                type="button"
                                onClick={onCancel}
                                className="btn-secondary"
                                disabled={isSubmitting}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn-danger"
                                disabled={
                                    isSubmitting ||
                                    confirmText.toLowerCase() !== 'delete my account' ||
                                    !Object.values(acknowledgments).every(Boolean)
                                }
                            >
                                {isSubmitting ? (
                                    <>
                                        <span className="spinner"></span>
                                        Deleting Account...
                                    </>
                                ) : (
                                    'Delete Account'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccountDelete;
