import React, { useState, useEffect } from 'react';
import API_CONFIG from '../../config/apiConfig';
import './css/PublicProfile.css';

const PublicProfile = ({ userId, onBack }) => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchPublicData();
    }, [userId]);

    const fetchPublicData = async () => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/public/${userId}`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
            });

            if (!response.ok) {
                throw new Error('User not found');
            }

            const data = await response.json();
            setUserData(data);
        } catch (err) {
            setError('Failed to load user information');
            console.error('Error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="public-profile-loading">
                <div className="loading-spinner"></div>
                <p>Loading profile...</p>
            </div>
        );
    }

    if (error || !userData) {
        return (
            <div className="public-profile-error">
                <div className="error-icon">⚠️</div>
                <h2>Profile Not Found</h2>
                <p>{error || 'This user profile is not available'}</p>
                <button onClick={onBack} className="back-home">
                    Back
                </button>
            </div>
        );
    }

    return (
        <div className="public-profile-container">
            <div className="public-profile-card">
                <div className="public-profile-header">
                    <div className="profile-avatar">
                        {userData.full_name?.charAt(0).toUpperCase() || '?'}
                    </div>
                    <div className="public-badge">Public Profile</div>
                </div>

                <div className="public-profile-content">
                    <div className="profile-field">
                        <div className="field-label">Full Name</div>
                        <div className="field-value">{userData.full_name}</div>
                    </div>

                    {userData.email && (
                        <div className="profile-field">
                            <div className="field-label">Email</div>
                            <div className="field-value">{userData.email}</div>
                        </div>
                    )}

                    {userData.birthday && (
                        <div className="profile-field">
                            <div className="field-label">Birthday</div>
                            <div className="field-value">{userData.birthday}</div>
                        </div>
                    )}

                    {userData.bio && (
                        <div className="profile-field">
                            <div className="field-label">Bio</div>
                            <div className="field-value">{userData.bio}</div>
                        </div>
                    )}
                </div>

                <div className="public-profile-footer">
                    <button onClick={onBack} className="home-button">
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PublicProfile;