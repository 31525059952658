import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Alert, AlertDescription } from '../ui/Alert';
import ProfileInfo from './ProfileInfo';
import EditProfileForm from './EditProfileForm';
import PasswordChange from './PasswordChange';
import AccountDeactivate from './AccountDeactivate';
import AccountDelete from './AccountDelete';
import './css/Profile.css';

const Profile = ({ setCurrentView }) => {
    const { user } = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    if (!user) {
        return (
            <div className="unauthorized-container">
                <div className="unauthorized-content">
                    <div className="lock-icon">
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.61461 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.61461 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <h2 className="unauthorized-title">Access Restricted</h2>
                    <p className="unauthorized-description">
                        Please log in to view and manage your profile information.
                    </p>
                    <div className="unauthorized-actions">
                        <button
                            onClick={() => setCurrentView('login')}
                            className="unauthorized-login-btn"
                        >
                            Log In
                        </button>
                        <button
                            onClick={() => setCurrentView('register')}
                            className="unauthorized-register-btn"
                        >
                            Create Account
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <main className="profile-container">
            <section className="profile-card">
                <header className="profile-header">
                    <div className="profile-avatar">
            <span className="avatar-initials">
              {user.full_name?.charAt(0) || 'U'}
            </span>
                    </div>
                    <h1>Edit Profile</h1>
                </header>

                <div className="profile-alerts">
                    {message && (
                        <Alert className="alert-success">
                            <AlertDescription>{message}</AlertDescription>
                        </Alert>
                    )}

                    {error && (
                        <Alert variant="destructive" className="alert-error">
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}
                </div>

                <div className="profile-content">
                    {editing ? (
                        <EditProfileForm
                            user={user}
                            onCancel={() => setEditing(false)}
                            onSuccess={(msg) => {
                                setMessage(msg);
                                setEditing(false);
                            }}
                            onError={setError}
                        />
                    ) : (
                        <div className="profile-sections">
                            <section className="profile-info-section">
                                <ProfileInfo user={user} />
                            </section>

                            <section className="profile-actions">
                                {!isChangingPassword && !isDeactivating && !isDeleting ? (
                                    <div className="action-buttons">
                                        <button
                                            onClick={() => setEditing(true)}
                                            className="btn btn-primary"
                                        >
                                            <svg className="btn-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Edit Profile
                                        </button>

                                        <button
                                            onClick={() => setIsChangingPassword(true)}
                                            className="btn btn-secondary"
                                        >
                                            <svg className="btn-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Change Password
                                        </button>

                                        <div className="danger-zone">
                                            <h2>Privacy</h2>
                                            <div className="danger-buttons">
                                                <button
                                                    onClick={() => setIsDeactivating(true)}
                                                    className="btn btn-warning"
                                                >
                                                    Deactivate Account
                                                </button>
                                                <button
                                                    onClick={() => setIsDeleting(true)}
                                                    className="btn btn-danger"
                                                >
                                                    Delete Account
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="modal-actions">
                                        {isChangingPassword && (
                                            <PasswordChange
                                                onCancel={() => setIsChangingPassword(false)}
                                                onSuccess={(msg) => {
                                                    setMessage(msg);
                                                    setIsChangingPassword(false);
                                                }}
                                                onError={setError}
                                            />
                                        )}
                                        {isDeactivating && (
                                            <AccountDeactivate
                                                onCancel={() => setIsDeactivating(false)}
                                                onError={setError}
                                            />
                                        )}
                                        {isDeleting && (
                                            <AccountDelete
                                                onCancel={() => setIsDeleting(false)}
                                                onError={setError}
                                            />
                                        )}
                                    </div>
                                )}
                            </section>
                        </div>
                    )}
                </div>
            </section>
        </main>
    );
};

export default Profile;
