import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/FriendRequests.css';

const FriendRequests = ({ onCancel, onSuccess, onError }) => {
    const { sessionToken, userId } = useContext(AuthContext);
    const [friendRequests, setFriendRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchFriendRequests = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/friend-requests/pending`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to fetch friend requests');
            const data = await response.json();
            setFriendRequests(data);
        } catch (error) {
            onError('Error fetching friend requests');
        } finally {
            setIsLoading(false);
        }
    };

    const respondToRequest = async (requestId, status) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/friend-requests/${requestId}`, {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
                body: JSON.stringify({ status }),
            });

            if (!response.ok) throw new Error('Failed to respond to friend request');
            setFriendRequests((prev) => prev.filter((req) => req.id !== requestId));
            onSuccess(`Friend request ${status === 'accepted' ? 'accepted' : 'declined'} successfully`);
        } catch (error) {
            onError('Error responding to friend request');
        }
    };

    useEffect(() => {
        fetchFriendRequests();
    }, []);

    return (
        <div className="friend-requests">
            <div className="form-header">
                <h2>Friend Requests</h2>
                <p>Manage pending friend requests</p>
            </div>

            {isLoading ? (
                <p>Loading friend requests...</p>
            ) : friendRequests.length === 0 ? (
                <p>No pending friend requests</p>
            ) : (
                <ul>
                    {friendRequests.map((req) => (
                        <li key={req.id}>
                            <span>{req.sender_full_name}</span> {/* Display sender's full name */}
                            <div className="actions">
                                <button
                                    onClick={() => respondToRequest(req.id, 'accepted')}
                                    className="btn-accept"
                                >
                                    Accept
                                </button>
                                <button
                                    onClick={() => respondToRequest(req.id, 'declined')}
                                    className="btn-decline"
                                >
                                    Decline
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            <div className="form-actions">
                <button onClick={onCancel} className="btn-cancel">
                    Back
                </button>
            </div>
        </div>
    );
};

export default FriendRequests;