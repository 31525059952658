import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import './css/Home.css';

const Home = ({ setCurrentView }) => {
    const { user, isAuthenticated } = useContext(AuthContext);

    const handleNavigation = (view) => {
        if (setCurrentView && typeof setCurrentView === 'function') {
            setCurrentView(view);
        }
    };

    return (
        <main className="home-container">
            <section className="hero-section" aria-labelledby="hero-title">
                <div className="hero-content">
                    <h1 id="hero-title" className="hero-title">
                        {isAuthenticated
                            ? (
                                <span className="welcome-text">
                                    Welcome back, <strong>{user?.full_name}</strong>!
                                </span>
                            )
                            : 'Welcome to BuyForS'
                        }
                    </h1>
                    {/*<p className="hero-description">*/}
                    {/*    {isAuthenticated*/}
                    {/*        ? 'Explore your personalized dashboard where you can manage your profile, track orders, and customize your preferences.'*/}
                    {/*        : 'Join our community to unlock exclusive features, personalized recommendations, and seamless shopping experience.'}*/}
                    {/*</p>*/}
                    {!isAuthenticated && (
                        <div className="hero-actions" role="group" aria-label="Authentication actions">
                            <button
                                onClick={() => handleNavigation('login')}
                                className="btn-primary"
                                type="button"
                            >
                                Login
                            </button>
                            <button
                                onClick={() => handleNavigation('register')}
                                className="btn-secondary"
                                type="button"
                            >
                                Register
                            </button>
                        </div>
                    )}
                </div>
                <div className="hero-image" role="presentation">
                    {/* Placeholder for future illustration */}
                </div>
            </section>
        </main>
    );
};

export default Home;