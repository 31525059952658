import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import './css/Navigation.css';

const Navigation = ({ currentView, setCurrentView }) => {
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const handleLogout = async () => {
        await logout();
        setCurrentView('login');
        setShowUserMenu(false);
    };

    return (
        <nav className="navigation">
            <div className="nav-container">
                {/* Logo and Brand */}
                <div className="nav-brand">
                    <button
                        onClick={() => setCurrentView('home')}
                        className="brand-button"
                    >
                        <svg className="brand-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="brand-name">BuyForS</span>
                    </button>
                </div>

                {/* Mobile Menu Button */}
                <button
                    className="mobile-menu-button"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <svg className="menu-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {isMenuOpen ? (
                            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        ) : (
                            <path d="M4 6h16M4 12h16M4 18h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        )}
                    </svg>
                </button>

                {/* Navigation Links */}
                <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    {isAuthenticated ? (
                        <>
                            <button
                                onClick={() => {
                                    setCurrentView('home');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'home' ? 'active' : ''}`}
                            >
                                Home
                            </button>

                            <button
                                onClick={() => {
                                    setCurrentView('searchFriends');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'searchFriends' ? 'active' : ''}`}
                            >
                                Search People
                            </button>

                            <button
                                onClick={() => {
                                    setCurrentView('manageFriends');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'manageFriends' ? 'active' : ''}`}
                            >
                                Manage Friends
                            </button>

                            <button
                                onClick={() => {
                                    setCurrentView('friendRequests');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'friendRequests' ? 'active' : ''}`}
                            >
                                Friend Requests
                            </button>

                            {/* User Menu */}
                            <div className="user-menu-container">
                                <button
                                    onClick={() => setShowUserMenu(!showUserMenu)}
                                    className="user-menu-button"
                                >
                                    <div className="user-avatar">
                                        {user?.full_name?.charAt(0) || 'U'}
                                    </div>
                                    <span className="user-name">{user?.full_name}</span>
                                    <svg className={`menu-arrow ${showUserMenu ? 'open' : ''}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 9l-7 7-7-7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>

                                {showUserMenu && (
                                    <div className="user-dropdown">
                                        <button
                                            onClick={() => {
                                                setCurrentView('profile');
                                                setShowUserMenu(false);
                                                setIsMenuOpen(false);
                                            }}
                                            className="dropdown-item"
                                        >
                                            Edit Profile
                                        </button>
                                        <button
                                            onClick={handleLogout}
                                            className="dropdown-item text-red-600"
                                        >
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => {
                                    setCurrentView('login');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'login' ? 'active' : ''}`}
                            >
                                Login
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentView('register');
                                    setIsMenuOpen(false);
                                }}
                                className={`nav-link ${currentView === 'register' ? 'active' : ''}`}
                            >
                                Register
                            </button>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navigation;