import React, { useState, useContext, useEffect } from 'react';
import { AuthProvider, AuthContext } from './context/AuthContext';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import ForgotPassword from './components/auth/ForgotPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import ResetPassword from './components/auth/ResetPassword';
import PublicProfile from './components/profile/PublicProfile';
import { Profile } from './components/profile';
import Home from './components/home/Home';
import Navigation from './components/home/Navigation';
import SearchPeople from './components/friends/SearchPeople';
import FriendList from './components/friends/FriendList';
import FriendRequests from './components/friends/FriendRequests';

const AppContent = () => {
    const { isAuthenticated, user } = useContext(AuthContext); // Assuming `user` has `id`
    const [currentView, setCurrentView] = useState(isAuthenticated ? 'home' : 'login');
    const [urlToken, setUrlToken] = useState(null);
    const [urlUserId, setUrlUserId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null); // For profile navigation

    useEffect(() => {
        // Check URL parameters and path
        const params = new URLSearchParams(window.location.search);
        const action = params.get('action');
        const token = params.get('token');
        const path = window.location.pathname;
        const userIdMatch = path.match(/^\/(\d+)$/); // Matches paths like /123

        if (userIdMatch) {
            // If URL is like localhost:3000/123
            setUrlUserId(userIdMatch[1]);
            setCurrentView('publicProfile');
        } else if (action && token) {
            // Handle action URLs
            switch (action) {
                case 'verify-email':
                    setCurrentView('verifyEmail');
                    setUrlToken(token);
                    break;
                case 'reset-password':
                    setCurrentView('resetPassword');
                    setUrlToken(token);
                    break;
                default:
                    break;
            }
        }
    }, []);

    const renderView = () => {
        switch (currentView) {
            case 'home':
                return <Home setCurrentView={setCurrentView} />;
            case 'login':
                return <LoginForm setCurrentView={setCurrentView} />;
            case 'register':
                return <RegisterForm setCurrentView={setCurrentView} />;
            case 'profile':
                return <Profile setCurrentView={setCurrentView} />;
            case 'forgotPassword':
                return <ForgotPassword setCurrentView={setCurrentView} />;
            case 'resetPassword':
                return <ResetPassword token={urlToken} setCurrentView={setCurrentView} />;
            case 'verifyEmail':
                return <VerifyEmail token={urlToken} setCurrentView={setCurrentView} />;
            case 'publicProfile':
                return (
                    <PublicProfile
                        userId={selectedUserId} // Use selected or URL-based user ID
                        onBack={() => setCurrentView('searchFriends')}
                    />
                );
            case 'searchFriends':
                return (
                    <SearchPeople
                        setCurrentView={setCurrentView}
                        setSelectedUserId={setSelectedUserId}
                        onSuccess={(message) => alert(message)}
                        onError={(error) => alert(error)}
                    />
                );
            case 'manageFriends':
                return <FriendList userId={urlUserId} setCurrentView={setCurrentView} />;
            case 'friendRequests':
                return <FriendRequests userId={urlUserId} onCancel={() => setCurrentView('home')} />;
            default:
                return <LoginForm setCurrentView={setCurrentView} />;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            {currentView !== 'publicProfile' && (
                <Navigation currentView={currentView} setCurrentView={setCurrentView} />
            )}
            <main className="max-w-7xl mx-auto px-4 py-8">
                {renderView()}
            </main>
        </div>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
};

export default App;