import React from 'react';
import './css/ProfileInfo.css';

const ProfileInfo = ({ user }) => {
    return (
        <div className="profile-info">
            <div className="info-header">
                <h2>Personal Information</h2>
            </div>

            <div className="info-grid">
                <div className="info-item">
                    <label>Email</label>
                    <div className="info-value">
                        <span className="text">{user.email}</span>
                        <span className="verified-badge" title="Verified Email">✓</span>
                    </div>
                </div>

                <div className="info-item">
                    <label>Full Name</label>
                    <div className="info-value">
                        <span className="text">{user.full_name}</span>
                    </div>
                </div>

                <div className="info-item">
                    <label>Phone Number</label>
                    <div className="info-value">
                        <span className="text">{user.phone_number || 'Not set'}</span>
                        {!user.phone_number && <span className="not-set-badge">Optional</span>}
                    </div>
                </div>

                <div className="info-item">
                    <label>Birthday</label>
                    <div className="info-value">
                        <span className="text">{new Date(user.birthday).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileInfo;
