import React, { createContext, useState, useEffect } from 'react';
import API_CONFIG from '../config/apiConfig';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children, setCurrentView }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(() => sessionStorage.getItem('userId'));
    const [isLoading, setIsLoading] = useState(true);

    const handleSessionExpired = () => {
        clearSession();
        if (setCurrentView) {
            setCurrentView('login');
        }
    };

    useEffect(() => {
        if (userId) {
            validateSession(userId);
        } else {
            setIsLoading(false);
        }
    }, [userId]);

    const validateSession = async (userId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/validate`, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
            });

            if (response.ok) {
                const userData = await fetchUserData(userId);
                setUser(userData);
                setIsAuthenticated(true);
            } else {
                if (response.status === 411) {
                    handleSessionExpired();
                } else {
                    clearSession();
                }
            }
        } catch (error) {
            console.error('Session validation error:', error);
            clearSession();
        } finally {
            setIsLoading(false);
        }
    };

    const clearSession = () => {
        setUser(null);
        setIsAuthenticated(false);
        setUserId(null);
        sessionStorage.removeItem('userId');
    };

    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}`, {
                method: 'GET',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
            });

            if (!response.ok) {
                if (response.status === 411) {
                    handleSessionExpired();
                }
                throw new Error('Failed to fetch user data');
            }

            const userData = await response.json();
            setUser(userData);
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    };

    const login = async (email, password) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include', // Include cookies in the response
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 403 && errorData.detail?.includes('deactivated')) {
                    throw new Error('Account is deactivated. Please check your email for reactivation instructions.');
                } else if (response.status === 401) {
                    throw new Error('Invalid email or password');
                } else if (response.status === 411) {
                    handleSessionExpired();
                } else {
                    throw new Error(errorData.detail || 'Login failed. Please try again.');
                }
            }

            const data = await response.json();
            sessionStorage.setItem('userId', data.user_id);
            setUserId(data.user_id);

            const userData = await fetchUserData(data.user_id);
            setUser(userData);
            setIsAuthenticated(true);
            return true;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/logout`, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
            });

            if (!response.ok && response.status !== 411) {
                console.error('Logout error:', await response.text());
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            clearSession();
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    return (
        <AuthContext.Provider value={{
            user,
            isAuthenticated,
            login,
            logout,
            fetchUserData,
            userId,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;