import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/FriendList.css';

const FriendList = ({ onCancel, onSuccess, onError }) => {
    const { sessionToken, userId } = useContext(AuthContext);
    const [friends, setFriends] = useState([]);
    const [blockStatuses, setBlockStatuses] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const fetchFriends = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/friends`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to fetch friends');

            const data = await response.json();

            if (!Array.isArray(data)) throw new Error('Invalid data format for friends');

            const filteredFriends = [];
            const blockStatusPromises = data.map(async (friend) => {
                try {
                    const isBlocked = await checkBlockStatus(friend.friend_id);
                    if (!isBlocked) {
                        filteredFriends.push(friend);
                    }
                    return { id: friend.friend_id, isBlocked };
                } catch (error) {
                    console.error(`Error checking block status for ${friend.friend_id}:`, error);
                    return { id: friend.friend_id, isBlocked: false };
                }
            });

            const statuses = await Promise.all(blockStatusPromises);
            const updatedBlockStatuses = statuses.reduce(
                (acc, { id, isBlocked }) => ({ ...acc, [id]: isBlocked }),
                {}
            );

            setBlockStatuses(updatedBlockStatuses);
            setFriends(filteredFriends);
        } catch (error) {
            console.error('Error fetching friends:', error);
            onError('Error fetching friends');
        } finally {
            setIsLoading(false);
        }
    };

    const checkBlockStatus = async (otherUserId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/block-status/${otherUserId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to fetch block status');
            return await response.json();
        } catch (error) {
            console.error(`Error checking block status for user ${otherUserId}:`, error);
            return false; // Default to not blocked
        }
    };

    const toggleBlockUser = async (blockedUserId) => {
        const isBlocked = blockStatuses[blockedUserId];
        const endpoint = `/api/${userId}/block/${blockedUserId}`;
        const method = isBlocked ? 'DELETE' : 'POST';

        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}${endpoint}`, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to toggle block status');

            setBlockStatuses((prev) => ({
                ...prev,
                [blockedUserId]: !isBlocked,
            }));

            onSuccess(isBlocked ? 'User successfully unblocked' : 'User successfully blocked');
        } catch (error) {
            console.error(`Error toggling block status for user ${blockedUserId}:`, error);
            onError('Error toggling block status');
        }
    };

    const removeFriend = async (friendId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/friends/${friendId}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to remove friend');

            setFriends((prev) => prev.filter((friend) => friend.friend_id !== friendId));
            onSuccess('Friend removed successfully');
        } catch (error) {
            console.error(`Error removing friend ${friendId}:`, error);
            onError('Error removing friend');
        }
    };

    useEffect(() => {
        fetchFriends();
    }, []);

    return (
        <div className="friend-list">
            <div className="form-header">
                <h2>Your Friends</h2>
                <p>View and manage your friends</p>
            </div>

            {isLoading ? (
                <p>Loading friends...</p>
            ) : friends.length === 0 ? (
                <p>You have no friends yet</p>
            ) : (
                <ul>
                    {friends.map((friend) => (
                        <li key={friend.friend_id}>
                            <span>{friend.friend_full_name}</span>
                            <div className="friend-actions">
                                <button
                                    onClick={() => removeFriend(friend.friend_id)}
                                    className="btn-remove"
                                >
                                    Remove
                                </button>
                                <button
                                    onClick={() => toggleBlockUser(friend.friend_id)}
                                    className="btn-block"
                                >
                                    {blockStatuses[friend.friend_id] ? 'Unblock' : 'Block'}
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            <div className="form-actions">
                <button onClick={onCancel} className="btn-cancel">
                    Back
                </button>
            </div>
        </div>
    );
};

export default FriendList;