import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/AccountManagement.css';

const AccountDeactivate = ({ onCancel, onDeactivate, onError }) => {
    const { sessionToken, userId, logout } = useContext(AuthContext);
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    const handleDeactivate = async () => {
      try {
        const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/deactivate`, {
          method: 'POST',
          credentials: 'include',
                headers: {
            'Content-Type': 'application/json',
            'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
            'Authorization': `Bearer ${sessionToken}`
          },
          body: JSON.stringify({ password })
        });

        if (response.status === 411) {
            handleSessionExpired();
            throw new Error('Session expired. Please login again.');
        }

        if (!response.ok) throw new Error('Failed to deactivate account');

        setTimeout(() => {
          logout();
        }, 2000);
      } catch (err) {
        onError('Failed to deactivate account. Please check your password and try again.');
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      try {
        await handleDeactivate();
        onDeactivate();
      } catch (error) {
        onError('Failed to deactivate account');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
        <div className="modal-overlay">
            <div className="account-modal deactivate">
                <div className="modal-header warning">
                    <div className="header-icon">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="warning-icon">
                            <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor"/>
                        </svg>
                    </div>
                    <h2>Deactivate Account</h2>
                    <p>This action will temporarily disable your account</p>
                </div>

                <div className="modal-body">
                    <div className="warning-box">
                        <h3>What happens when you deactivate your account?</h3>
                        <ul>
                            <li>Your profile will be hidden from other users</li>
                            <li>You can reactivate your account by logging in again</li>
                            <li>Your data will be preserved</li>
                            <li>Active subscriptions will be paused</li>
                        </ul>
                    </div>

                    <form onSubmit={handleSubmit} className="confirmation-form">
                        <div className="form-group">
                            <label htmlFor="deactivate-password">Enter your password</label>
                            <input
                                type="password"
                                id="deactivate-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="form-input"
                                placeholder="Enter your current password"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirm-text">
                                Type "deactivate" to confirm
                                <span className="confirmation-required">Required</span>
                            </label>
                            <input
                                type="text"
                                id="confirm-text"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                                required
                                className="form-input"
                                placeholder="deactivate"
                            />
                        </div>

                        <div className="modal-actions">
                            <button
                                type="button"
                                onClick={onCancel}
                                className="btn-secondary"
                                disabled={isSubmitting}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn-warning"
                                disabled={isSubmitting || confirmText.toLowerCase() !== 'deactivate'}
                            >
                                {isSubmitting ? (
                                    <>
                                        <span className="spinner"></span>
                                        Deactivating...
                                    </>
                                ) : (
                                    'Deactivate Account'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccountDeactivate;
