import React, { useState, useContext } from 'react';
import './css/PasswordChange.css';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';

const PasswordChange = ({ onCancel, onSuccess, onError }) => {
  const { sessionToken, userId } = useContext(AuthContext);
  const [passwordData, setPasswordData] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);

    const checkPasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[0-9]/.test(password)) strength += 1;
        if (/[^A-Za-z0-9]/.test(password)) strength += 1;
        setPasswordStrength(strength);
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData(prev => ({ ...prev, [name]: value }));
        if (name === 'new_password') {
            checkPasswordStrength(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordData.new_password !== passwordData.confirm_password) {
            onError('New passwords do not match');
            return;
        }
        setIsSubmitting(true);
        try {
          const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/update/${userId}/password`, {
            method: 'PUT',
            credentials: 'include',
                headers: {
              'Content-Type': 'application/json',
              'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
              'Authorization': `Bearer ${sessionToken}`
            },
            body: JSON.stringify({
              password: passwordData.current_password,
              new_password: passwordData.new_password
            })
          });
            onSuccess('Password updated successfully');
        } catch (error) {
            onError('Failed to update password');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="password-change-container">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-icon">
                        <svg className="key-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <h2>Change Password</h2>
                    <p>Please enter your current password and choose a new one</p>
                </div>

                <form onSubmit={handleSubmit} className="password-form">
                    <div className="form-group">
                        <label htmlFor="current_password">Current Password</label>
                        <div className="password-input-wrapper">
                            <input
                                type="password"
                                id="current_password"
                                name="current_password"
                                value={passwordData.current_password}
                                onChange={handlePasswordChange}
                                required
                                className="password-input"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="new_password">New Password</label>
                        <div className="password-input-wrapper">
                            <input
                                type="password"
                                id="new_password"
                                name="new_password"
                                value={passwordData.new_password}
                                onChange={handlePasswordChange}
                                required
                                className="password-input"
                                minLength="8"
                            />
                        </div>
                        <div className="password-strength">
                            <div className="strength-bars">
                                {[...Array(4)].map((_, index) => (
                                    <div
                                        key={index}
                                        className={`strength-bar ${index < passwordStrength ? 'active' : ''}`}
                                    />
                                ))}
                            </div>
                            <span className="strength-text">
                {passwordStrength === 0 && 'Too Weak'}
                                {passwordStrength === 1 && 'Weak'}
                                {passwordStrength === 2 && 'Medium'}
                                {passwordStrength === 3 && 'Strong'}
                                {passwordStrength === 4 && 'Very Strong'}
              </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="confirm_password">Confirm New Password</label>
                        <div className="password-input-wrapper">
                            <input
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                value={passwordData.confirm_password}
                                onChange={handlePasswordChange}
                                required
                                className="password-input"
                            />
                        </div>
                    </div>

                    <div className="password-requirements">
                        <h3>Password Requirements:</h3>
                        <ul>
                            <li className={passwordData.new_password.length >= 8 ? 'met' : ''}>
                                At least 8 characters long
                            </li>
                            <li className={/[A-Z]/.test(passwordData.new_password) ? 'met' : ''}>
                                Contains at least one uppercase letter
                            </li>
                            <li className={/[0-9]/.test(passwordData.new_password) ? 'met' : ''}>
                                Contains at least one number
                            </li>
                            <li className={/[^A-Za-z0-9]/.test(passwordData.new_password) ? 'met' : ''}>
                                Contains at least one special character
                            </li>
                        </ul>
                    </div>

                    <div className="modal-actions">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="btn-secondary"
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn-primary"
                            disabled={isSubmitting || passwordStrength < 3}
                        >
                            {isSubmitting ? (
                                <>
                                    <span className="spinner"></span>
                                    Updating Password...
                                </>
                            ) : (
                                'Update Password'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PasswordChange;
