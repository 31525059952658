import React, { useState } from 'react';
import { Alert, AlertDescription } from '../ui/Alert';
import API_CONFIG from '../../config/apiConfig';
import './css/Auth.css';

const ForgotPassword = ({ setCurrentView }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/forgot-password`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
                body: JSON.stringify({ email }),
                credentials: 'include', // Ensures cookies are included if needed
            });

            if (response.status === 411) {
                throw new Error('Session expired. Please login again.');
            }

            if (!response.ok) throw new Error('Failed to send reset email');

            setMessage('Password reset instructions have been sent to your email.');
            setError('');
            setTimeout(() => setCurrentView('login'), 3000);
        } catch (err) {
            console.error('Error:', err);
            setError(err.message || 'Failed to send reset email. Please try again.');
            setMessage('');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-card">
                <div className="auth-header">
                    <div className="auth-logo">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 20.25c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9z"
                            />
                        </svg>
                    </div>
                    <h1 className="auth-title">Forgot Password</h1>
                    <p className="auth-description">
                        Enter your email below to reset your password.
                    </p>
                </div>

                <div className="auth-form">
                    {message && (
                        <Alert className="success-message">
                            <AlertDescription>{message}</AlertDescription>
                        </Alert>
                    )}
                    {error && (
                        <Alert className="error-message" role="alert">
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">
                                Email Address
                            </label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`form-input ${error ? 'error' : ''}`}
                                required
                                aria-describedby={error ? 'error-message' : undefined}
                            />
                        </div>

                        <button
                            type="submit"
                            className="auth-btn"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <div className="spinner"></div>
                            ) : (
                                'Send Reset Instructions'
                            )}
                        </button>
                        <button
                            type="button"
                            className="auth-link"
                            onClick={() => setCurrentView('login')}
                        >
                            Back to Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;