import React, { useState, useEffect } from 'react';
import API_CONFIG from '../../config/apiConfig';
import './css/Auth.css';

const VerifyEmail = ({ token, setCurrentView }) => {
    const [status, setStatus] = useState('verifying'); // 'verifying', 'success', 'error'
    const [message, setMessage] = useState('');

    useEffect(() => {
        verifyEmail();
    }, [token]);

    const verifyEmail = async () => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/verify-email/${token}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                },
            });

            if (response.ok) {
                setStatus('success');
                setMessage('Email verified successfully!');
                // Redirect to login after 3 seconds
                setTimeout(() => {
                    setCurrentView('login');
                }, 3000);
            } else {
                const data = await response.json();
                throw new Error(data.detail || 'This verification link is invalid or has expired.');
            }
        } catch (err) {
            console.error('Verification error:', err);
            setStatus('error');
            setMessage(err.message || 'Failed to verify email. Please try again.');
        }
    };

    const getStatusIcon = () => {
        switch (status) {
            case 'verifying':
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="animate-spin">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                );
            case 'success':
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                );
            case 'error':
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                );
            default:
                return null;
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-card">
                <div className="auth-header">
                    <div className={`auth-logo ${status}`}>
                        {getStatusIcon()}
                    </div>
                    <h1 className="auth-title">
                        {status === 'verifying' ? 'Verifying Email' :
                            status === 'success' ? 'Email Verified' :
                                'Verification Failed'}
                    </h1>
                    <p className="auth-description">{message}</p>
                </div>

                <div className="auth-form">
                    {status === 'error' && (
                        <div className="flex flex-col gap-4">
                            <p className="text-sm text-gray-600 text-center">
                                Need a new verification link?
                            </p>
                            <button
                                onClick={() => setCurrentView('login')}
                                className="auth-button"
                            >
                                Back to Login
                            </button>
                        </div>
                    )}

                    {status === 'success' && (
                        <div className="text-center text-sm text-gray-600">
                            Redirecting to login page...
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;