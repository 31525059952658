import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/SearchPeople.css';

const SearchPeople = ({ setCurrentView, setSelectedUserId, onSuccess, onError }) => {
    const { sessionToken, userId } = useContext(AuthContext);
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [blockStatuses, setBlockStatuses] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/search?query=${query}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to search users');
            const data = await response.json();
            setSearchResults(data);

            // Check block statuses for each user in search results
            const statuses = {};
            for (const user of data) {
                statuses[user.id] = await checkBlockStatus(user.id);
            }
            setBlockStatuses(statuses);
        } catch (error) {
            onError('Error searching people');
        } finally {
            setIsLoading(false);
        }
    };

    const checkBlockStatus = async (otherUserId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/block-status/${otherUserId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to fetch block status');
            return await response.json();
        } catch (error) {
            console.error('Error checking block status:', error);
            return false;
        }
    };

    const toggleBlockUser = async (blockedUserId) => {
        const isBlocked = blockStatuses[blockedUserId];
        const endpoint = isBlocked
            ? `/api/${userId}/block/${blockedUserId}`
            : `/api/${userId}/block/${blockedUserId}`;
        const method = isBlocked ? 'DELETE' : 'POST';

        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}${endpoint}`, {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
            });

            if (!response.ok) throw new Error('Failed to toggle block status');

            // Update block statuses dynamically
            setBlockStatuses((prev) => ({
                ...prev,
                [blockedUserId]: !isBlocked,
            }));

            onSuccess(
                isBlocked
                    ? 'User successfully unblocked'
                    : 'User successfully blocked'
            );
        } catch (error) {
            onError('Error toggling block status');
        }
    };

    const sendFriendRequest = async (receiverId) => {
        try {
            const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/${userId}/friend-requests`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
                    
                },
                body: JSON.stringify({
                    sender_id: userId,
                    receiver_id: receiverId,
                }),
            });

            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(errorDetails?.detail || 'Failed to send friend request');
            }

            onSuccess('Friend request sent successfully!');
        } catch (error) {
            onError('Error sending friend request');
        }
    };

    return (
        <div className="search-people">
            <div className="form-header">
                <h2>Search People</h2>
                <p>Find and connect with others</p>
            </div>

            <div className="field-group">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="input-field"
                    placeholder="Enter a name"
                />
                <button onClick={handleSearch} className="btn-search" disabled={isLoading}>
                    {isLoading ? 'Searching...' : 'Search'}
                </button>
            </div>

            {searchResults.length > 0 && (
                <div className="results">
                    <h3>Results:</h3>
                    <ul>
                        {searchResults.map((user) => (
                            <li key={user.id}>
                                <span>{user.full_name}</span>
                                <div className="user-actions">
                                    <button
                                        onClick={() => sendFriendRequest(user.id)}
                                        className="btn-add"
                                    >
                                        Send Friend Request
                                    </button>
                                    <button
                                        onClick={() => {
                                            setSelectedUserId(user.id);
                                            setCurrentView('publicProfile');
                                        }}
                                        className="btn-view"
                                    >
                                        View Profile
                                    </button>
                                    <button
                                        onClick={() => toggleBlockUser(user.id)}
                                        className="btn-block"
                                    >
                                        {blockStatuses[user.id] ? 'Unblock' : 'Block'}
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchPeople;