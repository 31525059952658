import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './css/index.css';

console.log('Starting application initialization');

const renderApp = () => {
    try {
        console.log('Finding root element...');
        const rootElement = document.getElementById('root');

        if (!rootElement) {
            throw new Error('Root element not found');
        }

        console.log('Creating React root...');
        const root = ReactDOM.createRoot(rootElement);

        console.log('Rendering application...');
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );

        console.log('Application rendered successfully');
    } catch (error) {
        console.error('Failed to render application:', error);
        document.body.innerHTML = `
      <div style="padding: 20px; color: red;">
        <h1>Application failed to load</h1>
        <pre>${error.message}</pre>
      </div>
    `;
    }
};

renderApp();
