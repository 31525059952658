import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import API_CONFIG from '../../config/apiConfig';
import './css/EditProfileForm.css';

const EditProfileForm = ({ user, onCancel, onSuccess, onError }) => {
    const { sessionToken, userId, fetchUserData } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        full_name: user?.full_name || '',
        phone_number: user?.phone_number || '',
        birthday: user?.birthday || ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFieldUpdate = async (field, value) => {
      try {
        let requestBody = {};
        if (field === 'phone_number') {
          requestBody = { phone_number: value };
        } else if (field === 'full_name') {
          requestBody = { full_name: value };
        } else if (field === 'birthday') {
          requestBody = { birthday: value };
        }

        const response = await fetch(`${API_CONFIG.USER_SERVICE_BASE_URL}/api/update/${userId}/${field}`, {
          method: 'PUT',
          credentials: 'include',
                headers: {
            'Content-Type': 'application/json',
            'x-api-token': API_CONFIG.USER_SERVICE_API_SECRET_TOKEN,
            'Authorization': `Bearer ${sessionToken}`
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) throw new Error(`Failed to update ${field}`);

        await fetchUserData(userId, sessionToken);
        return true;
      } catch (err) {
        throw err;
      }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
          const updates = Object.keys(formData).filter(
            field => formData[field] !== user[field]
          );

          await Promise.all(
            updates.map(field => handleFieldUpdate(field, formData[field]))
          );
            onSuccess('Profile updated successfully');
        } catch (error) {
            onError('Failed to update profile');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="edit-form">
            <div className="form-header">
                <h2>Edit Profile</h2>
                <p>Update your personal information</p>
            </div>

            <div className="form-fields">
                <div className="field-group">
                    <label htmlFor="email">Email</label>
                    <div className="readonly-field">
                        <input
                            type="email"
                            id="email"
                            value={user.email}
                            disabled
                            className="input-readonly"
                        />
                        <span className="readonly-badge">Cannot be changed</span>
                    </div>
                </div>

                <div className="field-group">
                    <label htmlFor="full_name">
                        Full Name
                        <span className="required-badge">Required</span>
                    </label>
                    <input
                        type="text"
                        id="full_name"
                        value={formData.full_name}
                        onChange={(e) => setFormData({ ...formData, full_name: e.target.value })}
                        required
                        className="input-field"
                        placeholder="Enter your full name"
                    />
                </div>

                <div className="field-group">
                    <label htmlFor="phone_number">
                        Phone Number
                        <span className="optional-badge">Optional</span>
                    </label>
                    <input
                        type="tel"
                        id="phone_number"
                        value={formData.phone_number}
                        onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                        className="input-field"
                        placeholder="+1234567890"
                    />
                    <span className="field-hint">Format: +CountryCodeNumber</span>
                </div>

                <div className="field-group">
                    <label htmlFor="birthday">
                        Birthday
                        <span className="required-badge">Required</span>
                    </label>
                    <input
                        type="date"
                        id="birthday"
                        value={formData.birthday}
                        onChange={(e) => setFormData({ ...formData, birthday: e.target.value })}
                        required
                        className="input-field"
                    />
                </div>
            </div>

            <div className="form-actions">
                <button
                    type="button"
                    onClick={onCancel}
                    className="btn-cancel"
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn-save"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <span className="spinner"></span>
                            Saving...
                        </>
                    ) : (
                        'Save Changes'
                    )}
                </button>
            </div>
        </form>
    );
};

export default EditProfileForm;
